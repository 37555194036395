<template>
  <div class="login-box">
    <el-dialog
      :show-close="false"
      top="5vh"
      title="Service and Privacy Policy"
      :visible.sync="dialogVisible"
      width="50%">
      <ol class="policy-list">
        <li>Comply with local laws and regulations.</li>
        <li>Do not post politics, rumors, pornography and other improper public opinions. </li>
        <li>Do not visit maliciously, use loopholes and other means to violate regulations.</li>
        <li>I take full responsibility for the remarks I make.</li>
        <li>Do not use this product for fraud, malicious advertising and other illegal operations.</li>
        <li>When we provide services, we may collect, store and use the following information about you. If you do not provide relevant information, you may not be able to register as our user or enjoy some of the services we provide, or you may not be able to achieve the intended effect of the relevant services.</li>
        <li>Mobile phone number, obtain a mobile phone number to bind an account, and the country requires real-name authentication.</li>
        <li>Provide you with the services you use, and maintain and improve these services.</li>
        <li>When we provide services, it is used for identity verification, customer service, security protection, fraud monitoring, archiving and backup purposes to ensure the security of the products and services we provide to you.</li>
        <li>Let us better understand how you access and use our services, so as to respond to your individual needs in a targeted manner, such as language settings, location settings, personalized help services and instructions, or to you and other users make other responses.</li>
        <li>Recommend content that you may be interested in, including but not limited to sending you product and service information.</li>
        <li>We may use your personal information to prevent, detect, investigate fraud, endanger safety, illegal or violate agreements, policies or rules with us or its affiliates, to protect you, other our users, or us or its affiliates party's legitimate rights and interests.</li>
        <li>Other purposes with your permission</li>
        <li>Help us design new services and improve our existing services.</li>
        <li>The final interpretation right of all the above content belongs to this site.</li>
      </ol>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agreePolicy">
          <span :class="`agree-text ${agreeTime == 0 ? 'can-click':''}`" style="display: inline-block; width: 100px">{{ agreeTime == 0 ?'Confirm':`Confirm (${agreeTime}s)` }}</span>
        </el-button>
      </span>
    </el-dialog>
    <div class="login-form">
      <div class="login-card">
        <div @click="backHome" class="login-logo">
          <img width="100px" src="/logo.png" alt="">
          <p class="login-title">Textcome</p>
        </div>
        <form>
          <input type="text" name="userid" v-model="username" placeholder="Username">
          <input @keydown="loginInputKey" type="password" name="pswrd" v-model="password" placeholder="Password">
          <input @keydown="loginInputKey" type="button" @click="userLogin" name="login" class="login login-submit" value="Login">
          <el-checkbox class="remember-me" v-model="checked">Remember me in a week.</el-checkbox>
        </form>
        <div class="login-help">
          By signing up, you agree to the Terms of <span @click.stop="viewPolicy" class="policy">Service and Privacy Policy</span>, including Cookie Use.
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      username: '',
      password: '',
      checked: true,
      dialogVisible: false,
      agreeTime: 10,
      agreeTimeoutId: null
    }
  },
  created() {
    const jsonData = localStorage.getItem('login_info')
    if (jsonData) {
      const info = JSON.parse(jsonData)
      this.password = info.password
      this.username = info.username
    }
  },
  methods: {
    agreePolicy() {
      if (this.agreeTime>0) {
        this.$message.warning(`left ${this.agreeTime} second`)
      } else {
        this.dialogVisible = false
      }
    },
    backHome() {
      location.href = '/'
    },
    loginInputKey (e) {
      if (e.key === 'Enter') {
        this.userLogin()
        e.preventDefault()
      }
    },
    viewPolicy() {
      this.dialogVisible = true
      this.agreeTime = 10
      this._timeLeft()
    },
    _timeLeft() {
      this.agreeTimeoutId = setTimeout(() => {
        this.agreeTime--
        if (this.agreeTime == 0) {
          clearTimeout(this.agreeTimeoutId)
        } else {
          this._timeLeft()
        }
      }, 1000)
    },
    _autoLogin() {
      this.autoLogin && clearInterval(this.autoLogin)
      this.autoLogin = setInterval(() => {
        const info = {
          username: this.name, 
          password: this.password,
          rememberMe: this.checked
        }
        this.$store.dispatch('user/login',info).then(() => {
          localStorage.setItem('login_info', JSON.stringify(info))
        }).catch((msg) => {
          this.$message.error(msg)
          this.$router.push('/login')
        })
      }, 1000*60*25)
    },
    userLogin() {
      if (this.username && this.password) {
        const info = {
          username: this.username, 
          password: this.password,
          rememberMe: this.checked
        }
        this.$store.dispatch('user/login',info).then(() => {
          if (!this.checked) {
            this._autoLogin()
          }
          localStorage.setItem('login_info', JSON.stringify(info))
          this.$router.push('/main')
        }).catch((msg) => {
          this.$message.error(msg)
        })
      } else {
        if (!this.username) {
          this.$message('Username can not empty')
        } else if (!this.password) {
          this.$message('Password can not empty')
        }
      }
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.login-box {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  .login-title {
    font-weight: bold;
    font-size: 18px;
  }
}
.login-form {
  height: 450px;
  display: flex;
  align-items: center;
  text-align: left;
  box-shadow: 0px 0px 9px 0px silver;
  border-radius: 2px;
  .login-card {
    padding: 20px 40px;
    width: 400px;
    overflow: hidden;
    .login-logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .login-card p {
      margin-bottom: 40px;
      font-weight: 100;
      text-align: center;
      font-size: 1em;
    }
    
    input[type=submit] {
      width: 100%;
      display: block;
      margin-bottom: 10px;
      position: relative;
    }

    input[type=text], input[type=password] {
      height: 44px;
      font-size: 16px;
      width: 100%;
      margin-bottom: 10px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.561);
      outline: none;
      border: none;
      background: #fff;
      padding: 0 8px;
      border-radius: 4px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-shadow: 1px 1px 2px inset silver;
    }
  }
  .remember-me {
    margin-top: 8px;
    color: silver;
  }
}

.login {
  text-align: center;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  height: 36px;
  padding: 0 8px;
}

.login-submit {
  width: 100%;
  border: 0px;
  color: #fff;
  text-shadow: 0 1px #53a9ff8a; 
  background-color: #53a9ff;
  border-radius: 4px;
  cursor: pointer;
  transition: all .3s linear;
}

.login-submit:hover {
  border: 0px;
  text-shadow: 0 1px rgba(0,0,0,0.3);
  background-color: #53a9ffcd;
}


.policy {
  color: black;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  color: #53a9ff;
  transition: opacity ease 0.5s;
}

.policy:hover {
  opacity: 1;
  text-decoration: underline;
}
.policy:active {
  opacity: .8 !important;
  text-decoration: none;
}
.policy-list {
  text-align: left;
}
.agree-text {
  color: rgb(228, 228, 228);
  font-size: 16px;
}
.agree-text.can-click {
  color: white;
}
.login-help {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #666;
  text-align: left;
  margin-top: 20px;
}
</style>